<template>
  <!-- TO2020-1074 deletes the header completely -->
  <v-card v-if="false" :class="['header', 'responsive-base-card-header', `header-${typeComponent}`]">
    <v-card-title class="justify-space-between">
      <div class="b-left" @click="toHome">
        <v-img src="@/assets/images/header_logo01.png"
               contain
               width="213"
        />
      </div>
      <div class="b-right">
        <template v-if="business">
          <div>コールセンター業務連絡電話番号（非公開）</div>
          <h4>03ｰ3406ｰ0176</h4>
          <div>営業時間 9:30~19:00（休業：祝日・年末年始・臨時休あり）</div>
        </template>
        <template v-else>
          <v-img class="cus-log-contract"
                src="@/assets/images/com_tx01_01.png"
                contain
          />
          <p class="ma-0 time">(受付時間：10:00～17:00 年末年始・日曜・水曜除く)</p>
        </template>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>

export default {
  data: function () {
    return {}
  },
  props: {
    typeComponent: {
      type: String,
      defaultValue: ''
    },
    business: Boolean
  },
  components: {},
  methods: {
    toHome () {
      this.$router.push('/').catch(() => {})
    }
  },
  computed: {}
}
</script>
<style lang="scss">
.header {
  border-bottom: 2px solid #4b99d9 !important;
  .b-right {
    margin-left: auto;
    .cus-log-contract {
      width: 203px;
      margin-left: auto;
    }
    p.time {
      font-size: 14px;
      color: #9B9490;
    }
  }
  .b-left {
    .cus-logo-h2 {
      margin: 5px 0 0 25px;
      width: 130px;
    }
  }
}
</style>
